import { useState, useEffect, useContext } from 'react';
import Flatlist from 'flatlist-react';
import { Link, useNavigate } from 'react-router-dom';
import {Oval} from 'react-loader-spinner';
import axios from 'axios';

// STYLE LINKS
import '../css/components/scrollbar.css';
import '../css/screens/product/product.css';

// IMPORT ICONS
import { IoIosAdd } from 'react-icons/io';
import { IoLeafOutline } from 'react-icons/io5';
import { GrFormSubtract } from 'react-icons/gr';
import { BiChevronLeft, BiLeaf, BiWindowOpen } from 'react-icons/bi';
import { TbMeatOff } from 'react-icons/tb';
import { LuMilkOff, LuVegan } from 'react-icons/lu';
import { RiSubtractFill } from "react-icons/ri";

// FROM ANOTHER ARQUIVES
import { AlertMessage } from '../components/alertMessage';
import Loader from '../components/loader';
import { formatValue } from '../scripts/formatValue';
import RenderComplements from '../components/renderComplements';
import ProductContext from '../contexts/productContext';
import ScreenContext from '../contexts/screenContext';
import CompanyContext from '../contexts/companyContext';
import ClientDataContext from '../contexts/clientContext';
import { ServerPath } from '../classes/serverPath';
import BoxAlert from '../components/boxAlert';






function Product() {

  const {setScreen} = useContext(ScreenContext);

  const {companyData} = useContext(CompanyContext);

  const {clientData} = useContext(ClientDataContext);

  const {productData} = useContext(ProductContext);

  const [product, setProduct] = useState([]);

  const [complements, setComplements] = useState([]);

  const [complementsCount, setComplementsCount] = useState([]);

  const [observation, setObservation] = useState(null);

  const [quantity, setQuantity] = useState(1);
  const [prodPrice, setProdPrice] = useState(null);
  const [price, setPrice] = useState({ show: null, parse: null });


  // LOADING CONTROLLER ======================================>
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);

  // ALERT CONTROL
  const [warnAlert, setWarnAlert] = useState({show: false, title: null, text: null});



  useEffect(() => {
    (async () => {
      try {
        if(productData){
          await getProduct(productData.name, productData.id);

        } else {
          window.location.reload();
        }

      } catch (error) {
        // console.log('ERROR:', error);
        setErrorMessage(true);

      } finally {
        setLoading(false);
      }
    })();
  }, []);


  // GET PRODUCT DATA FUNCTION =============>
  const getProduct = async (name, id) => {

    try {
      let response = await axios.get(`${ServerPath}/smartmenu/get-product-data`, {
        params: {
          companyId: companyData.id,
          id: id,
          name: name,
        },
      });

      if (response.data) {
        let objectProd = {...response.data}
        // console.log('RESPONSE DATA:', objectProd);
        
        objectProd.prodPrice = formatValue(objectProd.price);
        objectProd.prodPromotionPrice = formatValue(objectProd.promotion_price);
        objectProd.complements = objectProd.complements ? objectProd.complements.split(',') : null;
        

        setProduct(objectProd);

        if (companyData.restriction && objectProd?.restricted && clientData && !clientData.older) {
          setWarnAlert({ show: true, title: 'Alerta', text: 'Produto não disponível:\n\nMotivo: Usuário menor de idade!'});
          setScreen('menu');

        } else {

          setPrice((prev) => {
            let currentPrice;

            if (response.data.promotion) {
              currentPrice = parseFloat(response.data.promotion_price);
              setProdPrice(currentPrice);
            } else {
              currentPrice = parseFloat(response.data.price);
              setProdPrice(currentPrice);
            }

            let res = formatValue(currentPrice);

            return { ...prev, show: res, parse: currentPrice };

          });

          if (objectProd.complements) {
            await getProductComplements(objectProd.complements.join(','));
          }
        }

      } else {
        setErrorMessage(true);
      }

    } catch (error) {
      console.error('ERROR - GET PRODUCT DATA:', error);
      throw error;
    }
  };

  // GET PRODUCT COMPLEMENTS DATA ===============>
  const getProductComplements = async (value) => {

    try {
      const response = await axios.get(`${ServerPath}/smartmenu/get-product-complements`, {
        params: {
          companyId: companyData.id,
          collections: value,
        },
      });

      if(response.status == 200){
        // console.log('RESPONSE:', response.data);

        setComplements(response.data);

      }

    } catch (error) {
      console.error('ERROR - GET PRODUCTS COMPLEMENTS:', error);
      throw error;
    }
  };



  // RENDER COMPLEMENTS COLLECTION FUNCTION ===============================>
  const renderComplements = (collection, index) => {
    // console.log('COLLECTION COMPLEMENTS:', collection.complements.length);
    const compList = collection.complements.sort((a, b) => a.position - b.position);

    return collection.complements.length > 0 && (
      <li key={index}>
        <div className="top-collection">
            <div className='collection-components'>
                <div className="title-area">
                    <h1 className="title-collection">{collection.name}</h1>

                    {/* SHOW / HIDDEN MAX ITEMS TO SELECT */}
                    {collection.max != null && collection.max > 0 ? <p className="max-options-collection">(Selecione {collection.max} itens)</p> : null}
                </div>

                {/* SHOW / HIDDEN MANDATORY ALERT */}
                {collection.isMandatory  ? (
                    <p className="mandatory-alert" style={{ backgroundColor: companyData.background_color, color: companyData.text_color }}>
                    Obrigatório
                    </p>
                ) : null}
                </div>

            </div>

        <div className="complements-area">
          {collection.complements.map((comp, compIndex) => {
            const compPrice = comp.price ? formatValue(comp.price) : 'Gratuito';

            const reachedMax = (comp.count == 1 && comp.max_one) ? true : false;
        
            return comp.view && (
              <li key={compIndex}>
                <div className="complements-component">
                  <div className="complements-informations">
                    <p className="text-complement" id="complement-name">
                      {comp.name}
                    </p>
                    {comp.description && (
                      <p className="text-complement" id="complement-description">
                        {comp.description}
                      </p>
                    )}
                    <p className="text-complement" id="complement-price" style={{ color: companyData.background_color }}>
                      {compPrice}
                    </p>
                  </div>
      
                  {/* <input type="checkbox" className="checkbox-complement"/> */}
      
                  <div className="complements-button-area">
                    {comp.max_one ? (
                        <input 
                          type='checkbox' 
                          className='checkbox-complement-button' 
                          disabled={complementsCount.filter((item) => item.id == comp.id).length == 1 ? false : collection.max && complementsCount.filter((item) => item.collectionId == collection.id).length === collection.max ? true : false}
                          check={{backgroundColor: companyData.text_color}} 
                          backgroundColor={companyData.text_color}
                          style={{accentColor: companyData.background_color}}
                          onClick={() => {
                            // FILTRA SE ESSE COMPLEMENTO JÁ FOI ADICIONADO
                            const validation = complementsCount.filter((item) => item.id == comp.id);

                            changeComplementsAmount(validation.length == 0 ? 'add' : 'minus', collection.id, comp.id, true);
                          }
                        }/>
      
                    ) : (
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <button className="complement-quantity" id="subtract-complement" onClick={() => changeComplementsAmount('minus', collection.id, comp.id)}>
                          <GrFormSubtract size={20} color="#444" />
                        </button>
      
                        <p className="complement-quantity" id="number-qtd">
                          {comp.count}
                        </p>
      
                        <button className="complement-quantity" id="add-complement" style={{backgroundColor: reachedMax && '#999'}} onClick={() => changeComplementsAmount('add', collection.id, comp.id)}>
                          <IoIosAdd size={20} color="#333" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            );
          })}
          
        </div>
      </li>
    );
  };


  useEffect(() => {
    console.log('LISTA:', complementsCount);
  }, [complementsCount])





  // CHANGE COMPLEMENTS AMOUNT FUNCTION ===========================>
  const changeComplementsAmount = (action, colId, compId, isCheckbox) => {
    let index = complements.findIndex((comp) => comp.id === colId);
    let index_comp = complements[index].complements.findIndex((comp) => comp.id == compId);
    
    let array = [...complements];

    let collection = complements[index];

    let comp = complements[index].complements[index_comp];

    let multiply = parseFloat(prodPrice * quantity);
    

    switch (action) {
      case 'add':

        // REALIZA UMA BUSCA PELA QUANTIDADE DE COMPLEMENTOS SELECIONADOS DENTRO DA COLEÇÃO
        // A FIM DE BARRAR A SELEÇÃO CASO A QUANTIDADE MAXIMA FOR EXCEDIDA
	

        if(collection.max){
          const objs = complementsCount.filter((item) => {
            return parseInt(item.collectionId) === parseInt(collection.id) 
          });

          const isCurrentObject = objs.some((item) => item.id == comp.id);
          
          if(objs.length == parseInt(collection.max) && !isCurrentObject){
            return;
          }
        }



        // CONTROLE DE SELEÇÃO UNITÁRIA PARA O COMPLEMENTO
        if(comp.max_one || isCheckbox){

          // CASO COMPLEMENTO JÁ TENHA SIDO SELECIONADOO, ELE ENTENDE QUE AGORA O MESMO DEVE SER REMOVIDO
          if(comp.count == 1){
            let countArray = [...complementsCount];
            const compIndex = countArray.findIndex((comp) => comp.id === compId);

            countArray.splice(compIndex, 1);
            setComplementsCount(countArray);

            comp.count = 0;
            setComplements(array);

            setPrice((prev) => {
              let value = prev.parse - (comp.price * quantity);

              return {...prev, show: formatValue(value), parse: value};
            });

            return;
          }
        }
    
        setPrice((prev) => {
          let value = prev.parse - comp.price * comp.count * quantity + comp.price * (comp.count + 1) * quantity;

          return { ...prev, show: formatValue(value), parse: value };
        });

        comp.count += 1;

        setComplementsCount((prev) => {
          let existComplement = false;

          if (prev.length > 0) {
            existComplement = prev.find((item) => item.id === compId);
          }

          if (existComplement) {
            return prev.map((comp) => {
              if (comp.id === compId) {
                return { ...comp, quantity: comp.quantity + 1 };
              }

              return comp;
            });
            
          } else {
            return [
              ...prev,
              {
                // CONFIGURA OS DADOS A SEREM INSERIDOS NO REGISTRO DO COMPLEMENTO SELECIONADO
                id: comp.id,
		            collectionId: comp.collection_id,
                complement: comp.name,
                price: comp.price,
                base_price: comp.price,
                ncm: comp.ncm,
                cst: comp.cst,
                quantity: 1,
              },
            ];
          }
        });

        break;

      case 'minus':
        if (comp.count > 0) {
          comp.count -= 1;
          setPrice((prev) => {
            let value = prev.parse - comp.price * quantity;

            return { ...prev, show: formatValue(value), parse: value };
          });
        }

        setComplementsCount((prev) => {
          if (comp.count == 0) {
            const removeEmptyComplement = prev.filter((item) => {
              return comp.count !== 0 || item.id !== compId;
            });

            return removeEmptyComplement;
          } else if (prev.id === compId) {
            return { ...prev, quantity: (prev.quantity -= 1) };
          }

          return prev;
        });

        break;

      default:
        break;
    }

    setComplements(array);
  };






  // FUNÇÃO DE ANÁLISE DA SELEÇÃO DOS COMPLEMENTOS DO PRODUTO
  const checkProductInstances = async () => {
    try{
      for(const collection of complements) {
        const soma = complements.reduce((total, item) => {
          return item.id == collection.id ? item.count + total : total;
          
        }, 0);
  
        if(collection.isMandatory){
          if(soma == 0){
            setWarnAlert({show: true, title: 'Alerta', text: `A coleção "${collection.name}" precisa ter pelo menos 1 de seus complementos selecionados!`});
            return;
          }
        }
        
        // if(collection.max){
        //   if(soma < parseInt(collection.max) && collection.isMandatory){
        //     setWarnAlert(`Alerta:\n\nSelecione ${collection.max} complementos na coleção "${collection.name}"!`);
        //     return;
        //   }
        // }
      }

    } finally {
      await checkCartData();
    }

  }

  // CHECK IF EXIST AN CAR IN THE NAVIGATOR LOCAL STORAGE
  const checkCartData = async () => {
    try {
      let cart = JSON.parse(localStorage.getItem('cart'));

      if (!cart) {
        cart = {
          companyId: companyData.id,
          status: true,
          datetime: new Date().toISOString().slice(0, 10) + ' ' + new Date().toLocaleTimeString(),
          products: [],
          cart_price: 0,
        };

        localStorage.setItem('cart', JSON.stringify(cart));
      }

      await saveProduct(cart);

    } catch (error) {
      console.error('ERROR - SAVE NEW CART:', error);
      setWarnAlert({ show: true, title: 'Erro', text: 'Não foi possível adicionar o produto ao carrinho! Tente novamente em instantes.'});
    }
  };

  // SAVE CART PRODUCT FUNCTION ==========================>
  const saveProduct = async (cart_data) => {
    try {
      let cart = cart_data;

      let indexPosition = cart.products.reduce((maxId, product) => Math.max(maxId, product.index), 0) + 1;

      let productData = {
        amount: quantity,
        id: product.id,
        index: indexPosition,
        name: product.name,
        complements: complementsCount,
        base_price: prodPrice,
        total_price: price.parse,
        ncm: product.ncm,
        cst: product.cst_csosn,
        restricted: product.restricted,
        image: product.image || 'https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg',
        observation: observation,
      };

      cart.products.push(productData);

      cart.cart_price += price.parse;

      localStorage.setItem('cart', JSON.stringify(cart));

      setScreen('menu');

    } catch (error) {
      console.error('ERROR - SAVE PRODUCT IN CART: ', error);
    }
  };

  const changeProductQuantity = (action) => {
    setQuantity((prevQuantity) => {
      let qtd = prevQuantity;
      switch (action) {
        case 'plus':
          qtd += 1;
          break;
        case 'minus':
          if (quantity > 1) {
            qtd -= 1;
          } else {
            qtd = 1;
          }
          break;
        default:
          break;
      }
      return qtd;
    });
  };

  // FUNCTION WHO CHANGES THE PRODUCT ORDER VALUE =======================>
  useEffect(() => {
    setPrice((prev) => {
      let value = 0;

      if (complementsCount.length > 0) {
        value = complementsCount.reduce((value, complement) => {
          return value + complement.price * complement.quantity * quantity;
        }, 0);
      }

      value += prodPrice * quantity;

      return { ...prev, show: formatValue(value), parse: value };
    });
  }, [quantity]);



  // CONTROLA O VALOR DO TEXTAREA DE OBSERVAÇÃO
  const handleChangeObservation = (event) => {
    setObservation(event.target.value);
  };





const ReturnButton = () => {
  return (
    <button className="return-button" style={{ backgroundColor: companyData.text_color }} onClick={() => setScreen('menu')}>
      <BiChevronLeft size={24} color={companyData.background_color} />
      <span style={{ color: companyData.background_color }}>Voltar</span>
    </button>
  )
}



  // <======================================== SCREEN ========================================>
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
            <section className="header-product" style={{...window.screen.width <= 768 && {...product.image ? {backgroundImage: `url(${ServerPath}/archives/images?dir=products&file=${product.image})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'} : { backgroundColor: companyData.background_color }}}}>
                <ReturnButton />
            </section>


          <div className="container">
            <div className="top-product-area">
              {product.promotion ? (
                <p className="promotion-alert" style={{ backgroundColor: companyData.background_color, color: companyData.text_color }}>
                  Em promoção!
                </p>
              ) : null}

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    {(window.screen.width > 768 && product.image) && (
                        <img src={`${ServerPath}/archives/images?dir=products&file=${product.image}` || null} className='lateral-product-image' style={{backgroundColor: '#fff'}} />
                    )}
                    <div style={{flexDirection: 'column', width: '100%'}}>
                        <h1 className="title-product" style={{...product.promotion && {width: '80%'}}}>{product.name}</h1>
                        
                        {product.promotion ? (
                            <p className="show-price">
                              <p style={{ color: 'red', textDecoration: 'line-through', marginLeft: 5, marginRight: 5 }}>{product.prodPrice}</p>
                              por
                              <p style={{ color: 'green', marginLeft: 5 }}>{product.prodPromotionPrice}</p>
                            </p>
                        ) : (
                            <p className="show-price">{product.prodPrice}</p>
                        )}

                        {product.description ? (
                            <p className="description-product">
                            <b>Descrição:</b> {product.description}
                            </p>
                        ) : null}

                    </div>
                </div>


                <div className="amostre-product-types">
                  <div className="type-list">
                    {product.organic && (
                      <div className="type-area" style={{ backgroundColor: companyData.background_color }}>
                        <IoLeafOutline size={20} color={companyData.text_color} />
                        <p style={{ marginLeft: 10, color: companyData.text_color }}>Orgânico</p>
                      </div>
                    )}
                    {product.vegetarian && (
                      <div className="type-area" style={{ backgroundColor: companyData.background_color }}>
                        <TbMeatOff size={20} color={companyData.text_color} />
                        <p style={{ marginLeft: 10, color: companyData.text_color }}>Vegetariano</p>
                      </div>
                    )}
                    {product.vegan && (
                      <div className="type-area" style={{ backgroundColor: companyData.background_color }}>
                        <LuVegan size={20} color={companyData.text_color} />
                        <p style={{ marginLeft: 10, color: companyData.text_color }}>Vegano</p>
                      </div>
                    )}
                    {product.contain_lactose && (
                      <div className="type-area" style={{ backgroundColor: companyData.background_color, minWidth: 130 }}>
                        <LuMilkOff size={20} color={companyData.text_color} />
                        <p style={{ marginLeft: 10, color: companyData.text_color }}>Sem lactose</p>
                      </div>
                    )}
                  </div>
                </div>
            </div>
	      
            {complements.length > 0 && (
              <Flatlist
                list={complements}
                renderItem={renderComplements}
                renderWhenEmpty={() => {
                  <div>
                    <p>Nada a apresentar</p>
                  </div>;
                }}
              />
            )}

            <div className="observations-area">
              <p className="label-input-observation">Observações:</p>
              <textarea 
	      	id="observation-input" 
	      	maxLength={255}
	      	style={{ resize: 'none', borderColor: companyData.background_color }} 
	      	placeholder="Deseja alterar algo no pedido?" 
	      	onChange={handleChangeObservation} 
	      />
            </div>

            <div className="bottom-area" style={{ backgroundColor: companyData.background_color }}>
              <div className="group-bottom-items">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <button className="box-quantity" id="minus-quantity-square" style={{ backgroundColor: companyData.text_color }} onClick={() => changeProductQuantity('minus')}>
                    <RiSubtractFill size={20} color={companyData.background_color} />
                  </button>

                  <div className="box-quantity" style={{ backgroundColor: companyData.text_color }}>
                    <p id="quantity-value" style={{ color: companyData.background_color }}>
                      {quantity}
                    </p>
                  </div>

                  <button className="box-quantity" id="add-quantity-square" style={{ backgroundColor: companyData.text_color }} onClick={() => changeProductQuantity('plus')}>
                    <IoIosAdd size={20} color={companyData.background_color} />
                  </button>
                </div>

                <button className="add-button" style={{ backgroundColor: companyData.text_color }} onClick={() => checkProductInstances()}>
                  {loadingButton ? (
                    <Oval 
                      visible={true}
                      height="45"
                      width="45"
                      color="#fff"
                    />
                  ) : (
                    <span className="add-btn-text" style={{ color: companyData.background_color }}>
                      Adicionar {price.show}
                    </span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <BoxAlert
        show={warnAlert.show}
        title={warnAlert.title}
        text={warnAlert.text}
        onClose={() => setWarnAlert({show: false, title: null, text: null})}
      />

    </div>
  );
}

export default Product;
