import React, { useState, useEffect, useRef, useContext } from 'react';
import FlatList from 'flatlist-react';
import { InputMask } from '@react-input/mask';
import axios from 'axios';

// IMPORT CSS
import '../css/screens/cart/cart.css';
import '../css/components/scrollbar.css';

// IMPORT ICONS
import { IoMdCloseCircle } from 'react-icons/io';
import { BsTrash } from 'react-icons/bs';
import { BiChevronUp, BiChevronDown, BiMinus, BiPlus } from 'react-icons/bi';

// FROM ANOTHER ARQUIVESS =====================>
import Loader from '../components/loader';
import { formatValue } from '../scripts/formatValue';
import { analyzeDateBirth } from '../scripts/analyzeDateBirth';
import ScreenContext from '../contexts/screenContext';
import CompanyContext from '../contexts/companyContext';
import LocationContext from '../contexts/locationContext';
import ClientDataContext from '../contexts/clientContext';
import BoxAlert from '../components/boxAlert';
import DocumentValidator from '../scripts/documentValidator';
import { ServerPath } from '../classes/serverPath';

const no_image_vector = require('../assets/no-image-vector.png');

function Cart() {
    // COMPANY DATA CONTROL =============================================>
    const { companyData } = useContext(CompanyContext);

    const { location } = useContext(LocationContext);

    const { setScreen } = useContext(ScreenContext);

    // CART DATA CONTROL ==============================>
    let cart = JSON.parse(localStorage.getItem('cart'));

    // CLIENT DATA CONTROL ============================>
    const clientArray = useRef(JSON.parse(localStorage.getItem('CLIENT_DATA')));

    const { setClientData } = useContext(ClientDataContext);

    const [products, setProducts] = useState([]);
    const productsRef = useRef(null);
    const [totalPrice, setTotalPrice] = useState(0);

    // LOADING CONTROL =============================>
    const [loading, setLoading] = useState(true);

    // ALERTS CONTROL ==============================================>
    const [requireDataAlert, setRequireDataAlert] = useState(false);
    const [NI, setNI] = useState(null);

    const [registerAlert, setRegisterAlert] = useState(false);

    const [olderAlert, setOlderAlert] = useState(false);

    const [clientNameAlert, setClientNameAlert] = useState(false);
    const [clientName, setClientName] = useState(null);

    const [clearAlert, setClearAlert] = useState({
        show: false,
        title: null,
        subtext: null,
        action: null,
    });

    const [warnAlert, setWarnAlert] = useState({ show: false, title: null, text: null });

    useEffect(() => {
        try {
            // document.title = `${companyData.name}`;

            if (cart && cart.products.length > 0) {
                let value = formatValue(cart.cart_price);
                setTotalPrice(value);

                let array = cart.products;
                array.map((item) => {
                    item.expanded = true;
                });

                setProducts(array);
            }

            const data = sessionStorage.getItem('CLIENT_DOCUMENT');

            if (data) {
                setNI(JSON.parse(data));
            }
        } finally {
            setLoading(false);
        }
    }, []);

    // EXPAND PRODUCT CONTROL ===========================>
    const expandProduct = (i) => {
        if (products) {
            let array = [...products];
            const index = array.findIndex((item) => item.index === i);

            // console.log('DENTRO DA FUNÇÃO DE EXPANSÃO:', array, index);

            array[index].expanded = !array[index].expanded;
            setProducts(array);
        }
    };

    // RENDER COMPLEMENTS FUNCION
    const renderComplements = (item, index) => {
        const price = item.price ? formatValue(item.price) : 'Gratuito';

        return (
            <li key={index}>
                <div className="complement-area">
                    <p className="text-complement" style={{ fontWeight: 600 }}>
                        {item.complement}
                    </p>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginTop: 2.5,
                        }}>
                        <p className="text-complement">{price}</p>
                        <p className="text-complement">Quantidade: {item.quantity}</p>
                    </div>
                </div>
            </li>
        );
    };

    // RENDER PRODUCTS FUNCTION ====================================================>
    const renderProducts = (item) => {
        const index = item.index;

        let price = formatValue(item.total_price);

        let basePrice = formatValue(item.total_price / item.amount);

        let formatName;

        if (window.innerWidth < 576) {
            formatName = item.name.slice(0, 20);

            if (item.name.length > 20) {
                formatName += '...';
            }
        } else if (window.innerWidth < 375) {
            formatName = item.name.slice(0, 15);

            if (item.name.length > 15) {
                formatName += '...';
            }
        } else {
            formatName = item.name;
        }

        // SAVE PRODUCT COMPLEMENTS LIST TO RENDER
        const complements = item.complements;

        return (
            <li key={index}>
                <div className="show-product">
                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                            marginTop: 10,
                            position: 'relative',
                        }}>
                        <img className="img-product-cart" src={item.image ? `${ServerPath}/archives/images?dir=products&file=${item.image}` : no_image_vector} />

                        <div
                            style={{
                                height: 80,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-around',
                                marginLeft: 10,
                            }}>
                            <p className="text-product">{formatName}</p>
                            <p className="text-product">{price}</p>
                            {item.amount > 1 && (
                                <p style={{ fontSize: 10, marginTop: -10 }}>
                                    ({basePrice} x {item.amount})
                                </p>
                            )}
                        </div>

                        {(item.complements.length > 0 || item.observation !== null) && (
                            <button className="open-product-btn" onClick={() => expandProduct(index)}>
                                {!item.expanded ? <BiChevronDown size={20} color="#333" /> : <BiChevronUp size={20} color="#333" />}
                            </button>
                        )}

                        <div className="quantity-buttons">
                            {item.amount == 1 ? (
                                <button
                                    className="exclude-prod-button"
                                    onClick={() => {
                                        setClearAlert({
                                            show: true,
                                            title: 'Remover produto',
                                            subtext: `Tem certeza que deseja remover o produto ${item.name}?`,
                                            action: () => excludeProd(index),
                                        });
                                    }}>
                                    <BsTrash size={13} color="#fff" />
                                </button>
                            ) : (
                                <button className="btn-qtd" style={{ backgroundColor: companyData.background_color }} onClick={() => changeAmount('minus', index)}>
                                    <BiMinus size={13} color={companyData.text_color} />
                                </button>
                            )}

                            <p className="prod-qtd-area">{item.amount}</p>

                            <button className="btn-qtd" style={{ backgroundColor: companyData.background_color }} onClick={() => changeAmount('add', index)}>
                                <BiPlus size={13} color={companyData.text_color} />
                            </button>
                        </div>
                    </div>

                    {item.expanded && (
                        <div className="opened-products-area">
                            <div className="complements-list-area">
                                <FlatList list={complements} renderItem={renderComplements} renderWhenEmpty={() => <h1></h1>} />
                            </div>

                            {item.observation && (
                                <div className="observation-area">
                                    <p className="label-observation">Observações:</p>
                                    <p className="observation-text" style={{ borderColor: companyData.background_color }}>
                                        {item.observation}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </li>
        );
    };

    // CHANGE PRODUCT AMOUNT FUNCTION ===============================>
    const changeAmount = (action, i) => {
        const index = products.findIndex((item) => item.index == i);
        let array = [...products];

        switch (action) {
            case 'minus':
                if (array[index].amount > 1) {
                    array[index].total_price -= array[index].total_price / array[index].amount;
                    array[index].amount -= 1;
                    setProducts(array);

                    cart.products[index].amount -= 1;
                    cart.products[index].total_price = array[index].total_price;
                    cart.cart_price -= array[index].total_price / array[index].amount;
                }
                break;

            case 'add':
                array[index].total_price += array[index].total_price / array[index].amount;
                array[index].amount += 1;
                setProducts(array);

                cart.products[index].amount += 1;
                cart.products[index].total_price = array[index].total_price;
                cart.cart_price += array[index].total_price / array[index].amount;

                break;

            default:
                break;
        }

        let total = formatValue(cart.cart_price);
        setTotalPrice(total);

        let cArray = JSON.stringify(cart);
        localStorage.setItem('cart', cArray);
    };

    // EXCLUDE PRODUCT FUNCTION ==============================>
    const excludeProd = async (i) => {
        // BUSCA O INDEX DO PRODUTO NA ARRAY CART
        let index = cart.products.findIndex((prod) => prod.index === i);

        cart.cart_price -= cart.products[index].total_price; // REDUZ O VALOR TOTAL DO CARRINHO

        const updateCart = cart.products.filter((product) => product.index !== i); // RETIRA O PRODUTO DA ARRAY

        if (updateCart.length > 0) {
            const array = updateCart.map((item) => {
                if (item.index > i) {
                    item.index -= 1;
                }
                return item;
            });

            console.warn('ARRAY:', array, '\n\n', cart.products);
            cart.products = array;
        } else {
            // CASO A ARRAY DE PRODUTOS ESTIVER VAZIA
            cart.products = [];
            cart.cart_price = 0;
        }

        setProducts(cart.products); // SALVA A NOVA ARRAY DE PRODUTOS
        productsRef.current = cart.products;

        localStorage.setItem('cart', JSON.stringify(cart)); // SALVA A NOVA ARRAY DE CART DENTRO DO LOCAL STORAGE

        let totalFormated = formatValue(cart.cart_price); // FORMATA O NOVO VALOR DO PREÇO TOTAL E O SALVA
        setTotalPrice(totalFormated);

        // FECHA O ALERTA DE EXCLUSÃO DE PRODUTO
        setClearAlert((prev) => {
            return { ...prev, show: false };
        });
    };

    // CLEAR CART FUNCTION
    const clearCart = async () => {
        try {
            setClearAlert((prev) => {
                return { ...prev, show: false };
            });

            setLoading(true);

            setProducts(null);

            products.ref = null;

            cart.products = [];
            cart.cart_price = 0;

            localStorage.setItem('cart', JSON.stringify(cart));
        } catch (error) {
            // console.log('Catch error:', error);
            window.alert('Erro\nNão foi possível retirar os produtos da sacola!\nTente novamente mais tarde.');
        } finally {
            setLoading(false);
        }
    };

    // CONTROLE DE RESTRIÇÃO =====================================================>
    const restrictionController = async () => {
        if (companyData.restriction && !clientArray.current.older) {
            const removedProducts = [];
            for (const item of cart.products) {
                if (item.restricted) {
                    removedProducts.push(item.name);
                    await excludeProd(item.index);
                }
            }

            // CONTROLE DE ALERTA
            if (removedProducts.length > 0) {
                setWarnAlert({ show: true, title: 'Alerta', text: `Os seguintes produtos foram removidos do seu pedido: ${removedProducts.join(', ')}.\n\nMotivo: Produtos restritos para menores de 18 anos!` });
            }
        }
    };

    // CREATE ORDER FUNCTION =============================>
    const makeOrder = async () => {
        try {
            setLoading(true);

	    setOlderAlert(false);

            productsRef.current = [...products];

            // console.log('1 - PRODUCTS:', productsRef.current);

            // PARA O SCRIPT CASO ALGUMA CONDIÇÃO NÃO SEJA ATENDIDA
            if (!clientArray.current || !clientArray.current.number) {
                // console.log('1.1');
                setRequireDataAlert(true);
                return;
            }

            // REALIZA UMA BUSCA PELO STATUS DA COMANDA
            const resTestCommand = await axios.get(`${ServerPath}/smartmenu/get-command-data`, {
                params: {
                    companyId: companyData.id,
                    number: clientArray.current.number,
                    id: clientArray.current.id,
                    tax: companyData.service_tax,
                },
            });

            // console.log('2', resTestCommand.data);

            if (resTestCommand.status == 200) {
                // console.log('2.1');

                if (!resTestCommand?.data?.status) {
                    // CASO A COMANDA JÁ ESTEJA FECHADA
                    localStorage.removeItem('CLIENT_DATA');

                    setClientData([]);

                    setRequireDataAlert(true);

                    // console.log('2.2');

                    return;
                }
            }

            await restrictionController();

            // console.log('3');

            if (productsRef.current?.length > 0) {
                // console.log('client array:', clientArray.current);
                // SALVA OS DADOS DE PEDIDO NA TABELA "orders"
                let res = await axios.post(`${ServerPath}/smartmenu/save-order`, {
                    companyId: companyData.id,
                    clientData: clientArray.current,
                    orderPrice: cart.cart_price,
                    openCart: cart.datetime,
                    products: productsRef.current,
                });

                if (res.status == 200) {
                    localStorage.removeItem('cart');
                    setScreen('account');
                }
            } else {
                setWarnAlert({ show: true, title: 'Alerta', text: 'Nenhum produto no carrinho para realizar o pedido! Selecione os produtos e depois retorne aqui para completar-mos seu pedido.' });
                await clearCart();
            }
        } catch (error) {
            console.error('ERROR - SAVE ORDER IN DB: ', error);
            setWarnAlert({ show: true, title: 'ERRO', text: `Não foi possível realizar seu pedido.\n${'Peça ajuda a um collaborador para solucionar o erro o mais rápido possível!'}` });
        } finally {
            setLoading(false);
        }
    };

    // CONTROLA A VERIFICAÇÃO DOS DADOS DO CLIENTE ==============================>
    const findClientData = async () => {
        try {
            let arrayData;

            const validNi = DocumentValidator(NI);

            if (!validNi) {
                setWarnAlert({ show: true, title: 'Erro', text: 'Erro:\n\nDigite os dados corretamente antes de continuar!' });
                return;
            }

            // BUSCA OS DADOS DO CLIENTE =========================>
            const resAccount = await axios.post(`${ServerPath}/smartmenu/find-client-command-data`, {
                companyId: companyData.id,
                NI: NI,
            });

            // DESLIGA O ALERTA DE REQUISIÇÃO DE DADOS
            setRequireDataAlert(false);

            if (resAccount.status == 200) {
                const res = resAccount.data;

                if (res.message == 'FIND-COMMAND') {
                    // console.log('ACABEI DE CHEGAR:', res.data);
                    saveClientData(res.data);

                    clientArray.current = res.data;

                    await makeOrder();
                } else if (res.message == 'NOT FOUND') {
                    arrayData = {
                        id: null,
                        name: null,
                        document: NI,
                        phone: null,
                        birth_date: null,
                        older: null,
                    };

                    clientArray.current = arrayData;

                    setRegisterAlert(true);
                } else {
                    // console.log('\n\nCLIENT ARRAY:', res.data);

                    // ANALISA OS DADOS DE IDADE DO CLIENTE
                    const analize = await analyzeDateBirth(res.data.birth_date);

                    // ATUALIZA OS DADOS DO CLIENTE
                    clientArray.current = res.data;
                    clientArray.current.older = analize.valid;

                    await analyzeData();
                }
            }
        } catch (error) {
            console.error('ERROR - FIND CLIENT DATA FUNCTION:', error);
            setWarnAlert({ show: true, title: 'Erro', text: 'Não foi possível prosseguir com a busca dos seus dados.\n\nTente novamente em instantes ou recorra a um colaborador para te ajudar!' });
        }
    };

    // Atualiza o nome do cliente dentro da array de dados utilizados na função de criação da comanda
    // useEffect(() => {
    //     if(clientArray.current && clientName){
    //         clientArray.current.name = clientName;
    //     }
    // }, [clientName])

    // ANALIZE DATA FUNCTION
    const analyzeData = async () => {
        try {
            if (!clientArray.current.name) {
                clientArray.current.name = clientName;
            }

            // INICIA CARREGAMENTO DE TELA
            setLoading(true);

            console.warn('ANALYZE DATA - LOCATION:', location);

            // ANALIZA OS DADOS PARA GERAR UMA COMANDA OU ENCONTRAR A ATUAL DO CLIENTE
            const resCommand = await axios.post(`${ServerPath}/smartmenu/analyze-command`, {
                companyId: companyData.id,
                data: clientArray.current,
                location: location,
            });

            let array;

            if (resCommand.status == 200) {
                // console.log('CHEGUEI:', resCommand.data.data);

                saveClientData(resCommand.data.data);

                await makeOrder(); // INICIA A FUNÇÃO DE REALIZAÇÃO DE PEDIDO
            }
        } catch (error) {
            console.error('ERROR - ANALIZE CLIENT DATA FUNCTION:', error);
            window.alert('Erro:\n\nNão foi possível conferir os dados da comanda.\nRecorra a um colaborador para que ele(a) possa te ajudar o mais rápido possivel!');
        } finally {
            setLoading(false);
        }
    };

    const saveClientData = (array) => {
        // console.log('SALVANDO DADOS DO CLIENTE: (cart)', array);
        localStorage.setItem('CLIENT_DATA', JSON.stringify(array)); // ATUALIZA OS VALORES DE CLIENTE NO LOCAL STORAGE

        clientArray.current = array;

        setClientData(array);
    };

    // CONTROLA O SALVAMENTO DO VALOR DE CPF DO CLIENTE
    const handleMaskCpf = ({ target: { value } }) => setNI(value);

    // CONTROLA O SALVAMENTO DO VALOR DE NOME DO CLIENTE
    const handleClientName = ({ target: { value } }) => setClientName(value);

    // SCREEN ===============================================================>

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div>
                    {/* <======================= HEADER =======================> */}
                    <header className="header-cart" style={{ backgroundColor: companyData.background_color }}>
                        <h1 className="title-cart" style={{ color: companyData.text_color }}>
                            Meu pedido:
                        </h1>
                    </header>

                    {/* <======================= PRODUCTS RENDER =======================> */}
                    {products && products.length > 0 ? (
                        <div className="products-area">
                            <div className="top-list">
                                <h1 className="title-list">Produtos:</h1>

                                <button
                                    className="exclude-btn"
                                    onClick={() => {
                                        setClearAlert({
                                            show: true,
                                            title: 'Esvaziar pedido',
                                            subtext: `Tem certeza que deseja esvaziar sua sacola?\nTodos os produtos serão apagados!`,
                                            action: () => clearCart(),
                                        });
                                    }}>
                                    <span>Esvaziar pedido</span>
                                </button>
                            </div>

                            <FlatList list={products} renderItem={renderProducts} />

                            <div className="informations-area">
                                <span className="total-value-area">
                                    <b>Total:</b> {totalPrice}
                                </span>
                                <button className="order-button" style={{ backgroundColor: companyData.background_color }} onClick={makeOrder}>
                                    <span style={{ color: companyData.text_color }}>Concluir</span>
                                </button>
                            </div>
                        </div>
                    ) : (
                        ({
                            /* <======================= EMPTY LIST ALERT =======================> */
                        },
                        (
                            <div className="empty-cart-alert">
                                <h1 className="title-alert-empty-cart">Ainda vazio por aqui!</h1>
                                <p className="subtext-alert-empty-cart">Adicione produtos a sua sacola e eles aprecerão aqui.</p>
                            </div>
                        ))
                    )}

                    {/* <====================== REQUEST DATA ALERT  ======================> */}
                    <BoxAlert 
                        show={requireDataAlert} 
                        title={'Insira seus dados'} 
                        text={<p>Vamos encontrar os dados da sua comanda</p>} 
                        showCloseButton={true} 
                        input={
                            <InputMask 
                                className="input-request-data" 
                                name="document" 
                                type="text" 
                                value={NI} 
                                onChange={handleMaskCpf} 
                                mask="___.___.___-__" 
                                replacement={{ _: /\d/ }} 
                                placeholder="000.000.000-00" 
                            />
                        } 
                        labelInput={'CPF:'} 
                        confirmButtonText={'Prosseguir'} 
                        confirmFunction={async () => await findClientData()} 
                        onClose={() => setRequireDataAlert(false)} 
                    />
                    

                    {/* <====================== REGISTER ALERT  ======================> 
                        (PARA O CASO DO CLIENTE NÃO TER UMA CONTRA PRÉ-REGISTRADA) 
                    */}
                    <BoxAlert
                        show={registerAlert}
                        title={'Não encontramos você!'}
                        text={
                            <p className="text-alert-box">
                                Infelizmente parece que você ainda não é registrado em nosso sistema.
                                <br />
                                <span style={{ color: companyData.background_color }}>Registre-se agora &nbsp;</span>e tenha acesso à<span style={{ color: companyData.background_color }}>&nbsp; diversos benefícios!</span>
                            </p>
                        }
                        showButtons={true}
                        cancelFunction={() => {
                            setClientNameAlert(true);
                            setRegisterAlert(false);
                        }}
                        cancelButtonText={'Voltar'}
                        confirmFunction={() => {
                            sessionStorage.setItem('CLIENT_DOCUMENT', JSON.stringify(NI));
                            setRegisterAlert(false);
                            setScreen('register');
                        }}
                        confirmButtonText={'Registrar'}
                        onClose={() => setRegisterAlert(false)}
                    />

                    {/* <====================== NAME REQUIRE ALERT  ======================> */}

                    <BoxAlert
                        show={clientNameAlert}
                        title={'Nome do cliente'}
                        text={<p className="text-alert-box">Insira seu nome para que possamos te encontrar e entregar seus pedidos sem erro!</p>}
                        input={<input type="text" className="input-request-data" value={clientName} onChange={handleClientName} placeholder={'Insira seu nome...'} />}
                        labelInput={'Nome completo:'}
                        confirmButtonText={'Continuar'}
                        onClose={() => {
                            clientArray.current.name = clientName;
                            setOlderAlert(true);
                            setClientNameAlert(false);
                        }}
                    />

                    {/* <====================== AGE REQUIRE ALERT  ======================> */}

                    <BoxAlert
                        show={olderAlert}
                        title={'Maior de idade'}
                        text={<p className="text-alert-box">A partir daqui você concorda em nos dizer, clicando nos botões a baixo, se é ou não maior de idade:</p>}
                        showButtons={true}
                        cancelButtonText={'-18'}
                        cancelFunction={() => {
                            clientArray.current.older = false;
                            analyzeData();
                        }}
                        confirmButtonText={'+18'}
                        confirmFunction={() => {
                            clientArray.current.older = true;
                            analyzeData();
                        }}
                        onClose={() => setOlderAlert(false)}
                    />

                    {/* <====================== CLEAR / REMOVE PRODUCTS ALERT  ======================> */}

                    <BoxAlert
                        show={clearAlert.show}
                        title={clearAlert.title}
                        text={<p className="text-alert-box">{clearAlert.subtext}</p>}
                        showButtons={true}
                        cancelButtonText={'Cancelar'}
                        cancelFunction={() =>
                            setClearAlert((prev) => {
                                return { ...prev, show: false };
                            })
                        }
                        confirmButtonText={'Apagar'}
                        confirmFunction={() => clearAlert.action()}
                        onClose={() => setClearAlert({ show: false, title: null, subtext: null, action: null })}
                    />

                    {/* <====================== WARN ALERT  ======================> */}

                    <BoxAlert 
                        show={warnAlert.show} 
                        title={warnAlert.title} 
                        text={<p className="text-alert-box">{warnAlert.text}</p>}
                        onClose={() => setWarnAlert({ show: false, title: null, text: null })} 
                    />
                </div>
            )}
        </div>
    );
}

export default Cart;
