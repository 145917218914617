
import React, { useContext, useEffect, useState } from 'react';
import Flatlist from 'flatlist-react';
import axios from 'axios';

// STYLE LINKS
import '../css/screens/menu/menu.css';

// IMPORT ICONS
import { IoCloseCircleSharp } from 'react-icons/io5';

// FROM ANOTHER ARCHIVES
import Loader from '../components/loader';
import { formatValue } from '../scripts/formatValue';
import CompanyContext from '../contexts/companyContext';
import ProductContext from '../contexts/productContext';
import ScreenContext from '../contexts/screenContext';
import ClientDataContext from '../contexts/clientContext';
import BoxAlert from '../components/boxAlert';
import { ServerPath } from '../classes/serverPath';
import LoadingContext from '../contexts/loadingContext';
import CatalogContext from '../contexts/catalogContext';

const no_image_vector = require('../assets/no-image-vector.png');

function Menu() {
    // COMPANY DATA CONTROL
    const { companyData } = useContext(CompanyContext);

    const { setScreen } = useContext(ScreenContext);

    const textColor = companyData.text_color === '#fff' || companyData.text_color === '#ffff' ? 'black' : companyData.text_color;

    const { setProductData } = useContext(ProductContext);

    // CLIENT DATA CONTROL
    const { clientData } = useContext(ClientDataContext);

    // MENU SCREEN DATA
    // const [catalog, setCatalog] = useState([]);
    const {catalog, setCatalog} = useContext(CatalogContext);

    const [maxLength, setMaxLength] = useState(50);

    const [mapCategories, setMapCategories] = useState([]);
    const [category, setCategory] = useState(null);

    // LOADING CONTROL
    // const {setLoading} = useContext(LoadingContext);
    const [loading, setLoading] = useState(true);




    //  INITIALIZE SCREEN
    useEffect(() => {
        try {
            setLoading(true);

            (async () => {
                await getCatalog();
            })();

        } catch (error) {
            console.error('ERROR - CONSTRUCT SCREEN FUNCTION:', error);

        } finally {
            setLoading(false);
        }
    }, []);




    // GET CATEGORIES FUNCTION ==========================>
    const getCatalog = async () => {
        try {
            let response = await axios.get(`${ServerPath}/smartmenu/get-catalog`, {
                params: {
                    companyId: companyData.id,
                },
            });

            if (response.status == 200) {

                // CONTROLA OS DADOS A SEREM INSERIDOS NO CATALOGO
                const data = [];

                for (const cat of response.data?.categories) {
                    const groupProducts = response.data?.products.filter((product) => {
                        const isValid = (product.restricted && !clientData) || (product.restricted && clientData && clientData.older) || (product.restricted && !companyData.restriction) || !product.restricted;

                        if (product.category === cat.name && product.view && isValid) {
                            return product;
                        }
                    });

                    data.push({ ...cat, products: groupProducts });
                }

                setCatalog(data);
            }
            
        } catch (error) {
            console.error('ERROR - GET CATEGORIES FUNCTION:', error);
        }
    };


    // CHANGE THE FIRST CATEGORY TO RENDER BASED IN YOUR POSITION VALUE
    useEffect(() => {
        try {
            let first = catalog.sort((a, b) => a.position - b.position);
            setCategory(first[0].name);

        } finally {
            return;
        }
    }, [catalog]);


    // RENDER CATEGORIES FUNCTION ================================>
    const renderCategories = (item, index) => {

        return (
            item.products.length > 0 && (
                <li key={index}>
                    <a href={`#${item.name}`}>
                        <button id={`cat${index}`} className="btn-category" onClick={() => setCategory(item.name)}>
                            <p style={{ color: category == item.name ? companyData.background_color : '#b3b3b3' }}>{item.name}</p>
                        </button>
                    </a>
                </li>
            )
        );
    };


    // FUNÇÃO DE RENDERIZAÇÃO DO CATÁLOGO DO MENU
    const renderCatalog = (item, index) => {

	const products = item.products.sort((a, b) => a.position - b.position);

        return (
            item.products.length > 0 && (
                <li key={index}>
                    <div id={item.name}>
                        <h1 className="title-categories-prod-list">{item.name}</h1>

                        <>
                            {item.products.map((prod, index) => {
                                let ideal = null;

                                if (window.innerWidth <= 500) {
                                    ideal = 40;
                                } else if (window.innerWidth <= 768) {
                                    ideal = 60;
                                } else if (window.innerWidth > 768) {
                                    ideal = 80;
                                }

                                let itemDescription;

                                if (prod.description) {
                                    itemDescription = ideal ? prod.description.slice(0, ideal).concat('...') : prod.description;
                                }

                                // const nameLink = prod.name.replace(/ /g, '-');

                                // FORMAT PRICE VALUES ==========>
                                const price = formatValue(prod.price);

                                const promotionPrice = prod.promotion && prod.promotion_price ? formatValue(prod.promotion_price) : null;

                                // CONTROLS THE PRODUCTS WHO WILL NOT BE SHOW TO CLIENTS UNDER 18 YEARS
                                if ((companyData.restriction && prod.restricted && clientData && !clientData.older) || !prod.view) {
                                    return;

                                } else {
                                    return (
                                        <li key={index}>
                                            <button
                                                className="product-component"
                                                style={{ backgroundColor: prod.purchasable == false || (prod.useStock == true && prod.stockQuantity == 0) ? '#999' : '#fff' }}
                                                onClick={() => {
                                                    if (!companyData.status || prod.purchasable == false || (prod.useStock == true && prod.stockQuantity == 0)) {
                                                        return;
                                                    } else {
                                                        setProductData(prod);
                                                        setScreen('product');
                                                    }
                                                }}>
                                                <img className="img-product-menu" src={prod.image ? `${ServerPath}/archives/images?dir=products&file=${prod.image}` : no_image_vector} />

                                                <div className="products-text-area">
                                                    <p className="text-products" id="product-name">
                                                        {prod.name}
                                                    </p>

                                                    {/* ITEM DESCRIPTION CAN BE A NULL VALUE */}
                                                    {prod.description && (
                                                        <p className="text-products" id="product-description" style={{ color: '#555' }}>
                                                            {itemDescription}
                                                        </p>
                                                    )}

                                                    {prod.promotion == true ? (
                                                        <div className="text-products" style={{ display: 'flex', flexDirection: 'row' }}>
                                                            <p id="old-price" style={{ color: 'red', textDecoration: 'line-through', marginRight: 10 }}>
                                                                {price}
                                                            </p>
                                                            {/* <span id='separator-promotion-price' style={{ marginLeft: 5, marginRight: 5, }}>por</span> */}
                                                            <p id="new-price" style={{ color: 'green' }}>
                                                                {promotionPrice}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <p className="text-products" id="product-price">
                                                            {price}
                                                        </p>
                                                    )}
                                                </div>
                                            </button>
                                        </li>
                                    );
                                }
                            })}
                        </>
                    </div>
                </li>
            )
        );
    };




    // CONTROLA O EVENTO DE SCROLL PARA MODIFICAR O POSICIONAMENTO DA HEADER
    document.addEventListener('scroll', () => {
        const scrollY = window.scrollY;

        const categoryArea = document.getElementById('categories-area');

        // const spacing = window.screen.width > 768 ? 150 : 120;
        const spacing = 130;

        if (categoryArea) {
            if (scrollY > spacing) {
                categoryArea.style.width = window.screen.width <= 480 ? '100%' : window.screen.width > 480 && window.screen.width <= 768 ? '100%' : '65%';
                categoryArea.style.position = 'fixed';
                categoryArea.style.top = 0;
                if (window.screen.width > 768) {
                    categoryArea.style.borderBottomLeftRadius = '20px';
                    categoryArea.style.borderBottomRightRadius = '20px';
                }
            } else {
                categoryArea.style.width = '100%';
                categoryArea.style.position = 'relative';
                categoryArea.style.bottom = 0;
                categoryArea.style.borderBottomLeftRadius = 0;
                categoryArea.style.borderBottomRightRadius = 0;
            }
        }
    });




    // SCREEN ===================================================================>
    return (
        <div className="App">
            <>
                {/* <======================= HEADER =======================> */}
                <header className="header-menu">
                    <div className="company-identity-area">
                        {companyData.use_image && companyData.image && <img src={`${ServerPath}/archives/images?dir=logos&file=${companyData.image}`} className="header-company-logo" alt={'Logo'} />}

                        <div className="company-identity-text-area">
                            {/* COMPANY NAME IN HEADER */}
                            <h1 className={'name-company'}>{companyData.name}</h1>

                            <p className="text-status-alert" style={{ color: '#fff', padding: 5, borderRadius: 5, backgroundColor: companyData.status ? 'green' : 'red' }}>
                                {companyData.status ? 'Aberto' : 'Fechado'}
                            </p>
                        </div>
                    </div>

                    {/* <======================= CATEGORIES =======================> */}
                    <div id="categories-area">
                        <div id="categories" style={{ backgroundColor: companyData.background_color }}>
                            {catalog.length > 0 ? (
                                <div id="scroll-category" className="scroll-category">
                                    <div id="category-list" className="category-list">
                                        <Flatlist list={catalog} renderItem={renderCategories} sortBy="position" />
                                    </div>
                                </div>
                            ) : (
                                <p style={{ color: '#fff', textAlign: 'center', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto' }}>Suas categorias de produtos ficarão aqui!</p>
                            )}
                        </div>
                    </div>
                </header>

                {/* <======================= PRODUCTS AREA =======================> */}
                <section id="products-area">
                    {catalog.length > 0 ? (
                        <div className="products-list">
                            <Flatlist list={catalog} renderItem={renderCatalog} sortBy="position" />
                        </div>
                    ) : (
                        <p style={{ width: '80%', alignSelf: 'center', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: 100, backgroundColor: '#d3d3d3', padding: 10, borderRadius: 5 }}>Adicione novos produtos a conta e eles irão aparecer aqui!</p>
                    )}
                </section>
            </>
        </div>
    );
}

export default Menu;
