/*  PROPS CONTROLLER:

    - show
    - title
    - text
    - showInput
    - inputLabel
    - showButtons
    - confirmFunction
    - confirmButtonText
    - cancelFunction
    - cancelButtonText
    - onClose
*/

import { useContext } from 'react';

// STYLE LINKS
import '../css/components/boxAlert.css';

// IMPORT ICONS
import { IoMdCloseCircle } from 'react-icons/io';

// FROM ANOTHER ARCHIVES
import CompanyContext from '../contexts/companyContext';

export default function BoxAlert(props) {
    const { companyData } = useContext(CompanyContext);

    return (
        <>
            {props.show ? (
                <div className="alert-component">
                    <div className="overlay-cart"></div>

                    <div className="alert-box">
                        {props.showCloseButton ? (
                            <button className="close-data-request" onClick={() => props.onClose()}>
                                <IoMdCloseCircle size={25} color="#333" />
                            </button>
                        ) : null}

                        <h1 className="title-alert-box" style={{ marginBottom: 0 }}>
                            {props.title}
                        </h1>

                        {props.text && <p className="text-alert-box" style={{paddingBottom: props.input ? 0 : 60}}>{props.text}</p>}

                        {props.input && (
                            <div className="input-request-area">
                                {props.inputLabel && <p className="label-request-data">{props.inputLabel}</p>}
                                {props.input}
                            </div>
                        )}

                        {!props.showButtons ? (
                            <button className="proceed-request-data-btn" style={{ backgroundColor: companyData.background_color }} onClick={() => props.confirmFunction ? props.confirmFunction() : props.onClose()}>
                                <p className="text-alert-button" style={{ color: companyData.text_color }}>
                                    {props.confirmButtonText || 'Ok'}
                                </p>
                            </button>

                        ) : (
                            <div className="buttons-box-area">
                                <button className="button-box-config" id="left-box-button" style={{ backgroundColor: companyData.background_color, borderColor: companyData.text_color }} onClick={() => props.cancelFunction()}>
                                    <p className="text-alert-button" style={{ color: companyData.text_color }}>
                                        {props.cancelButtonText || 'Voltar'}
                                    </p>
                                </button>
                                <button className="button-box-config" id="right-box-button" style={{ backgroundColor: companyData.background_color, borderColor: companyData.text_color }} onClick={() => props.confirmFunction()}>
                                    <p className="text-alert-button" style={{ color: companyData.text_color }}>
                                        {props.confirmButtonText || 'Prosseguir'}
                                    </p>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : null}
        </>
    );
}
