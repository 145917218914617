import { useState, useEffect } from "react";
import FlatList from "flatlist-react";
import { Link } from "react-router-dom";
import axios from "axios";

// STYLE LINKS
import '../css/components/scrollbar.css';
import "../css/screens/account/account.css";

// IMPORT ICONS
import { BiChevronUp, BiChevronDown } from "react-icons/bi";

// FROM ANOTHER ARCHIVES
import BottomNavigator from "../components/bottomNavigator";
import { formatValue } from "../scripts/formatValue";
import Loader from "../components/loader";
import { useContext } from "react";
import CompanyContext from "../contexts/companyContext"; 
import ClientDataContext from "../contexts/clientContext";
import { ServerPath } from "../classes/serverPath";
import BoxAlert from "../components/boxAlert";

function Account() {
  
  const {companyData} = useContext(CompanyContext);

  const {clientData, setClientData} = useContext(ClientDataContext);

  // const textColor =
  //   companyData.text_color === "#fff" || companyData.text_color === "#ffff"
  //     ? companyData.background_color
  //     : companyData.text_color;
  
  const textColor = companyData.background_color;

  const [orders, setOrders] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [couvert, setCouvert] = useState({using: false, price: null});
  const [subtotalValue, setSubtotalValue] = useState(0);
  const [tax, setTax] = useState(0);

  const [expandOrders, setExpandOrders] = useState(true);

  // LOADING CONTROLLER
  const [loading, setLoading] = useState(true);

  // WARN CONTROL
  const [warnAlert, setWarnAlert] = useState({show: false, title: null, text: null});




  useEffect(() => {
    (async () => {
      if (clientData && clientData.number) {
        await testCommand();
        await getOrders();

      } else {
        setLoading(false);
      }
    })();
  }, []);

  // TEST COMMAND FUNCTION ============================>
  const testCommand = async () => {
    try {
      // console.log('CLIENT DATA:', clientData);

      const res = await axios.get(
        `${ServerPath}/smartmenu/get-command-data`,
        {
          params: {
            companyId: companyData.id,
            number: clientData.number,
	    id: clientData.id,
	    tax: companyData.service_tax,
          },
        }
      );

      if (res.status === 200) {
	// console.log('RES DATA COMMAND:', res.data);

        if (!res.data?.status) {
          localStorage.clear("CLIENT_DATA");

          setClientData([]);
          
        } else {
          setCouvert(formatValue(res.data.price.couvert));

          setSubtotalValue(formatValue(res.data.price.subtotal));
          
          setTax(formatValue(res.data.price.service_tax));

          setTotalPrice(formatValue(res.data.price.total));

        }
      }
      
    } catch (error) {
      console.error('ERROR - TEST COMMAND FUNCTION:', error);
    }
  };

  // GET CLIENT ORDERS FUNCTION
  const getOrders = async () => {
    try {
      let res = await axios.get(
        `${ServerPath}/smartmenu/get-client-orders`,
        {
          params: {
            companyId: companyData.id,
            command: clientData.number,
          },
        }
      );

      if (res.status === 200) {
        // console.log('PEDIDOS:', res.data);
        setOrders(res.data.sort((a, b) => b.code - a.code));

      } else {
        setExpandOrders(true);
      }

    } catch (error) {
      console.error("ERROR - GETTING USER ORDERS: ", error);
      setWarnAlert({show: true, title: 'Erro', text: 'Não foi possível encontrar seus pedidos. Tente novamente em instantes!'});

    } finally {
      setLoading(false);
    }
  };



  // RENDER ORDERS FUNCTION ==============================>
  const renderOrders = (item, index) => {
    // console.log('ITEM:', item);
    let price = formatValue(item.price);

    let day = new Date(item.datetime).toLocaleDateString();
    let time = new Date(item.datetime).toLocaleTimeString();

    let date = `${day}, ${time}`;

    return (
      <li key={index}>
        <div className="order-area-render">
          <p className="text-order-box">
            <b>Pedido:</b> {item.code}
          </p>
  	  <p className="text-order-box">
    		<b>{item.location == 0 ? 'Local:' : 'Mesa:'}</b>{item.location == 0 ? ' Balcão' : item.location}
          </p>
          <p className="text-order-box">
            <b>Preço:</b> {price}
          </p>
          <p className="text-order-box">
            <b>Data / Hora:</b> {date}
          </p>
          <p className="text-order-box">
            <b>Status:</b> {item.status ? "Em preparo" : 'Entregue'}
          </p>

          <div className="products-order-area" style={{marginTop: 10, padding: 10, backgroundColor: '#b6b6b6', borderRadius: 10 }}>
            <p classsName='text-order-box'><b>Produtos:</b></p>
            {item.products && item.products.map((prod, index) => (
              <div className="product-area" style={{marginLeft: 10, marginTop: 10}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                  <p className="product-text">x{prod.amount}&nbsp;-&nbsp;{prod.name}</p>
                  <p className="product-text">{formatValue(prod.price)}</p>
                </div>
                <div className="complements-area">
                  {prod.complements && prod.complements.map((comp, index) => (
                    <div className="comp-data" style={{marginLeft: 15, display: 'flex', flexDirection: 'row'}}>
                      <p className="complement-text">x{comp.quantity}&nbsp;{comp.complement}</p>
                    </div>
                      
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </li>
    );
  };




  // SCREEN =========================>
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ paddingBottom: 150 }}>
          <section
            className="header-account"
            style={{ backgroundColor: companyData.background_color }}
          >
            <h1
              className="account-title"
              style={{ color: companyData.text_color }}
            >
              Conta:
            </h1>
          </section>

          {clientData && clientData.id ? (
            <div>
              <section className="data-area">
                <p className="data-area-title" style={{padding: '5px 10px 5px 10px', backgroundColor: '#d3d3d3', textAlign: 'center', fontSize: 12, position: 'absolute', top: 10, right: 10, borderRadius: 10}}>Seus dados</p>

                <p className="text-data-area">
                  {" "}
                  <b style={{ color: textColor }}>Comanda:</b>{" "}
                  {clientData.number}
                </p>
                <p className="text-data-area">
                    {" "}
                    <b style={{ color: textColor }}>Nome:</b>{" "}
                    {clientData.client_name}
                </p>
                <p className="text-data-area">
                  {" "}
                  <b style={{ color: textColor }}>CPF:</b> {clientData.client_document}
                </p>
                {clientData.client_phone && (
                  <p className="text-data-area">
                    {" "}
                    <b style={{ color: textColor }}>Telefone:</b>{" "}
                    {clientData.client_phone}
                  </p>
                )}
                <p className="text-data-area">
                  {" "}
                  <b style={{color: textColor}}>{clientData.location == 0 ? 'Local:' : 'Mesa:'}</b> {clientData.location == 0 ? 'Balcão' : clientData.location}
                </p>
              </section>


                {/* ÁREA DOS PEDIDOS DO CLIENTE */}
              <div className="orders-area">
                <div className="top-orders-area">
                  <h1 className="orders-title">pedidos</h1>
                  <button
                    className="expand-orders-list-account"
                    onClick={() => setExpandOrders((prev) => !prev)}
                  >
                    {expandOrders ? <BiChevronUp size={30} color="#333" /> : <BiChevronDown size={30} color="#333" />}
                  </button>
                </div>

                {orders && expandOrders ? (
                  <FlatList
                    list={orders}
                    renderItem={renderOrders}
                    renderWhenEmpty={() => (
                      <p
                        style={{
                          marginTop: 10,
                          marginBottom: 10,
                          textAlign: "center",
                        }}
                      >
                        Nenhum pedido encontrado!
                      </p>
                    )}
                  />
                ) : null}

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <span className="show-total-price-account">
                    Subtotal:
                  </span>
                  <span className="show-total-price-account">
                    {subtotalValue}
                  </span>
                </div>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <span className="show-total-price-account">
                    Taxa de serviço:
                  </span>
                  <span className="show-total-price-account">
                    {tax}
                  </span>
                </div>

                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <span className="show-total-price-account">
                      Couvert:
                    </span>
                    <span className="show-total-price-account">
                      {couvert}
                    </span>
                  </div>

                <span className="show-total-price-account" style={{alignSelf: 'center', textAlign: 'center',fontSize: '16pt'}}>
                  Total: {totalPrice}
                </span>
              </div>

              {/* {orders[0] && (
                        <button className="finish-account-button" style={{ backgroundColor: companyData.background_color }}>
                            <span style={{ color: textColor }}>Fechar conta</span>
                        </button>
                    )} */}
            </div>
          ) : (
            <div className="not-logged-alert">
              <h1 className="title-not-logged-alert">Sem dados de comanda:</h1>
              <p className="subtext-not-logged-alert">
                Realize um pedido para que nós possamos gerar sua comanda.
                <br />
                <p style={{ marginTop: 10, fontWeight: 600 }}>
                  Após isso, todos os dados dos seus pedidos e conta serão
                  apresentados aqui!
                </p>
              </p>
            </div>
          )}

          <BottomNavigator />
        </div>
      )}



      <BoxAlert
        show={warnAlert.show}
        title={warnAlert.title}
        text={warnAlert.text}
        onClose={() => setWarnAlert({show: false, title: null, text: null})}
      />
    </>
  );
}

export default Account;
