import { useContext, useEffect, useState } from 'react';
import '../css/components/bottomMenu.css';

// IMPORT ICONS ==================>
import { SlNotebook } from 'react-icons/sl';
import { BsFillBagFill } from 'react-icons/bs';
import { IoReceiptOutline } from 'react-icons/io5';
import CompanyContext from '../contexts/companyContext';
import ScreenContext from '../contexts/screenContext';



export default function BottomNavigator({ changeScreen }) {
  
  const { companyData } = useContext(CompanyContext);
  
  const {screen, setScreen} = useContext(ScreenContext);

  const [countProducts, setCountProducts] = useState(0);



  useEffect(() => {
    findProducts();

    window.addEventListener('storage', findProducts);

    return () => {
      window.removeEventListener('storage', findProducts);
    };
  }, []);


  function findProducts() {
    let res = JSON.parse(localStorage.getItem('cart'));

    if (res && res.companyId !== companyData.id) {
      localStorage.clear('cart');
    }

    let soma = 0;

    if (res) {
      soma = res.products.reduce((soma, item) => soma + item.amount, 0);
    }

    setCountProducts(soma);
  }


  useEffect(() => {
    setTimeout(findProducts(), 1000);
  }, [countProducts, screen]);


  return (
    <section className="bottom-menu" style={{ backgroundColor: companyData.background_color }}>
      <button className="iconBtn" onClick={() => setScreen('menu')}>
        <SlNotebook color={companyData.text_color} size={30} />
      </button>

      <button className="iconBtn" onClick={() => setScreen('cart')}>
        <BsFillBagFill color={companyData.text_color} size={30}/>
        {countProducts > 0 && (
          <p className="count-products" style={{ backgroundColor: companyData.background_color, color: companyData.text_color }}>
            {countProducts}
          </p>
        )}
      </button>

      <button className="iconBtn" onClick={() => setScreen('account')}>
        <IoReceiptOutline color={companyData.text_color} size={30} />
      </button>
    </section>
  );
}
